import { useMemo } from "react";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

let store;

const initialState = {
  user: null,
  search: "",
  reestr_filter_extended: false,
  reestr_filter_type: [],
  reestr_filter_sort: "-id",
  reestr_filter_withphoto: false,
  reestr_filter_canpurchase: false,
  reestr_filter_year: "",
  filteredVarieties: [],
  nextPage: "",
  mycollection_search: "",
  mycollection_filter_extended: false,
  mycollection_filter_type: [],
  mycollection_filter_sort: "name",
  mycollection_filter_year: "",
  mycollectionFilteredVarieties: [],
  mycollectionNextPage: "",
  mycollectionTotal: 0,

  usercollectionCurrentUser: false,

  usercollection_search: "",
  usercollection_filter_extended: false,
  usercollection_filter_type: [],
  usercollection_filter_sort: "name",
  usercollection_filter_canpurchase: false,
  usercollection_filter_year: "",

  usercollectionFilteredVarieties: [],
  usercollectionNextPage: "",

  usersSearch: "",
  filteredUsers: [],
  usersNextPage: "",
  hybridizersSearch: "",
  filteredHybridizers: [],
  hybridizersNextPage: "",
  imSearch: "",
  filteredIms: [],
  imsNextPage: "",
  token: null,
  goodToken: null,
  authDone: false,
  goto: null,
  snackbarType: false,
  snackbarMessage: "",
  typeOptions: [],
  typeOptionsSort: [],
  unreadMessages: 0,
  basketItemsNum: 0,
  baskets: [],
  basketItemsIds: [],

  importData: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SETSNACKBAR":
      return {
        ...state,
        snackbarType: action.snackbarType,
        snackbarMessage: action.snackbarMessage,
      };
    case "SETSNACKBARCLEAR":
      return {
        ...state,
        snackbarType: false,
        snackbarMessage: "",
      };
    case "SETTYPEOPTIONS":
      return {
        ...state,
        typeOptions: action.typeOptions,
      };
    case "SETTYPEOPTIONSSORT":
      return {
        ...state,
        typeOptionsSort: action.typeOptionsSort,
      };
    case "SETUSER":
      return {
        ...state,
        user: action.user,
      };
    case "SETGOTO":
      return {
        ...state,
        goto: action.goto,
      };
    case "SETTOKEN":
      return {
        ...state,
        token: action.token,
      };
    case "SETGOODTOKEN":
      return {
        ...state,
        goodToken: action.goodToken,
      };
    case "SETAUTHDONE":
      return {
        ...state,
        authDone: action.authDone,
      };
    case "SEARCH":
      return {
        ...state,
        search: action.search,
      };
    case "SETREESTRFILTEREXTENDED":
      return {
        ...state,
        reestr_filter_extended: action.value,
      };
    case "SETREESTRFILTERTYPE":
      return {
        ...state,
        reestr_filter_type: action.value,
      };
    case "SETREESTRFILTERSORT":
      return {
        ...state,
        reestr_filter_sort: action.value,
      };
    case "SETREESTRFILTERWITHPHOTO":
      return {
        ...state,
        reestr_filter_withphoto: action.value,
      };
    case "SETREESTRFILTERCANPURCHASE":
      return {
        ...state,
        reestr_filter_canpurchase: action.value,
      };
    case "SETREESTRFILTERYEAR":
      return {
        ...state,
        reestr_filter_year: action.value,
      };
    case "MYCOLLECTION_SEARCH":
      return {
        ...state,
        mycollection_search: action.mycollection_search,
      };
    case "SETMYCOLLECTIONFILTEREXTENDED":
      return {
        ...state,
        mycollection_filter_extended: action.value,
      };
    case "SETMYCOLLECTIONFILTERTYPE":
      return {
        ...state,
        mycollection_filter_type: action.value,
      };
    case "SETMYCOLLECTIONFILTERSORT":
      return {
        ...state,
        mycollection_filter_sort: action.value,
      };
    case "SETMYCOLLECTIONFILTERYEAR":
      return {
        ...state,
        mycollection_filter_year: action.value,
      };
    case "SETFILTEREDVARIETIES":
      return {
        ...state,
        filteredVarieties: action.filteredVarieties,
      };
    case "SETNEXTPAGE":
      return {
        ...state,
        nextPage: action.nextPage,
      };
    case "RESETFILTEREDVARIETIES":
      return {
        ...state,
        filteredVarieties: [],
        nextPage: "",
      };

    case "SETMYCOLLECTIONFILTEREDVARIETIES":
      return {
        ...state,
        mycollectionFilteredVarieties: action.mycollectionFilteredVarieties,
      };
    case "SETMYCOLLECTIONNEXTPAGE":
      return {
        ...state,
        mycollectionNextPage: action.mycollectionNextPage,
      };
    case "SETMYCOLLECTIONTOTAL":
      return {
        ...state,
        mycollectionTotal: action.mycollectionTotal,
      };
    case "RESETMYCOLLECTION":
      return {
        ...state,
        mycollectionFilteredVarieties: [],
        mycollectionNextPage: "",
        mycollectionTotal: 0,
      };

    case "SETUSERCOLLECTIONCURRENTUSER":
      return {
        ...state,
        usercollectionCurrentUser: action.usercollectionCurrentUser,
      };
    case "SETUSERCOLLECTIONFILTEREDVARIETIES":
      return {
        ...state,
        usercollectionFilteredVarieties: action.usercollectionFilteredVarieties,
      };
    case "SETUSERCOLLECTIONNEXTPAGE":
      return {
        ...state,
        usercollectionNextPage: action.usercollectionNextPage,
      };
    case "RESETUSERCOLLECTION":
      return {
        ...state,
        usercollectionFilteredVarieties: [],
        usercollectionNextPage: "",
        usercollectionCurrentUser: false,
      };

    case "USERCOLLECTION_SEARCH":
      return {
        ...state,
        usercollection_search: action.usercollection_search,
      };
    case "SETUSERCOLLECTIONFILTEREXTENDED":
      return {
        ...state,
        usercollection_filter_extended: action.value,
      };
    case "SETUSERCOLLECTIONFILTERTYPE":
      return {
        ...state,
        usercollection_filter_type: action.value,
      };
    case "SETUSERCOLLECTIONFILTERSORT":
      return {
        ...state,
        usercollection_filter_sort: action.value,
      };
    case "SETUSERCOLLECTIONFILTERYEAR":
      return {
        ...state,
        usercollection_filter_year: action.value,
      };
    case "SETUSERCOLLECTIONFILTERCANPURCHASE":
      return {
        ...state,
        usercollection_filter_canpurchase: action.value,
      };
    case "USERSSEARCH":
      return {
        ...state,
        usersSearch: action.search,
      };
    case "SETFILTEREDUSERS":
      return {
        ...state,
        filteredUsers: action.filteredUsers,
      };
    case "SETUSERSNEXTPAGE":
      return {
        ...state,
        usersNextPage: action.usersNextPage,
      };
     case "HYBRIDIZERSSEARCH":
      return {
        ...state,
        hybridizersSearch: action.search,
      };
    case "SETFILTEREDHYBRIDIZERS":
      return {
        ...state,
        filteredHybridizers: action.filteredHybridizers,
      };
    case "SETHYBRIDIZERSNEXTPAGE":
      return {
        ...state,
        hybridizersNextPage: action.hybridizersNextPage,
      };
    case "IMSSEARCH":
      return {
        ...state,
        imSearch: action.search,
      };
    case "SETFILTEREDIMS":
      return {
        ...state,
        filteredIms: action.filteredIms,
      };
    case "SETIMSNEXTPAGE":
      return {
        ...state,
        imsNextPage: action.imsNextPage,
      };
    case "SETUNREADMESSAGES":
      return {
        ...state,
        unreadMessages: action.unreadMessages,
      };
    case "SETBASKETITEMSNUM":
      return {
        ...state,
        basketItemsNum: action.basketItemsNum,
      };
    case "SETBASKETITEMSIDS":
      return {
        ...state,
        basketItemsIds: action.basketItemsIds,
      };
    case "SETBASKETS":
      return {
        ...state,
        baskets: action.baskets,
      };
    case "SETIMPORTDATA":
      return {
        ...state,
        importData: action.importData,
      };
    default:
      return state;
  }
};

function initStore(preloadedState = initialState) {
  return createStore(
    reducer,
    preloadedState,
    composeWithDevTools(applyMiddleware())
  );
}

export const initializeStore = (preloadedState) => {
  let _store = store ?? initStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState,
    });
    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === "undefined") return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
};

export function useStore(initialState) {
  const store = useMemo(() => initializeStore(initialState), [initialState]);
  return store;
}
