
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import { Provider } from "react-redux";
import { useStore } from "../store";
import React from "react";
import Head from "next/head";
import dynamic from "next/dynamic";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { CacheProvider } from "@emotion/react";
import createEmotionCache from "../lib/createEmotionCache";
import { createTheme, useMediaQuery } from "@mui/material";
const MySnackbar = dynamic(() => import("../lib/snackbar"));
const clientSideEmotionCache = createEmotionCache();

const __Page_Next_Translate__ = function App(props) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const store = useStore(pageProps.initialReduxState);
  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          vkbutton: {
            main: "#6db7ff",
            contrastText: "#FFF",
          },
          okbutton: {
            main: "#EE8208",
            contrastText: "#FFF",
          },
          //mode: "light",
          mode: prefersDarkMode ? "dark" : "light",
          ...(prefersDarkMode === false
            ? //...(true
              {
                // light
                back: {
                  //mainBack: "#007aff",
                  mainBottomBack: "#ffffff",
                  searchBack: "#e5e5e5",
                },
              }
            : {
                // dark
                back: {
                  mainBottomBack: "#272727",
                  searchBack: "#3c3c3c",
                },
              }),
        },
      }),
    [prefersDarkMode]
  );

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link rel="shortcut icon" href="/static/favicon.ico" />
        <link rel="manifest" href="/static/manifest.json" />
        <link
          href="/static/favicon-16x16.png"
          rel="icon"
          type="image/png"
          sizes="16x16"
        />
        <link
          href="/static/favicon-32x32.png"
          rel="icon"
          type="image/png"
          sizes="32x32"
        />
        <link rel="apple-touch-icon" href="/static/apple-icon-152x152.jpg" />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/static/apple-icon-152x152.jpg"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/static/apple-icon-180x180.jpg"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/static/apple-icon-152x152.jpg"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/static/apple-icon-144x144.jpg"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/static/apple-icon-120x120.jpg"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/static/apple-icon-114x114.jpg"
        />
        <meta name="yandex-verification" content="2ab477115199441f" />
      </Head>

      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
          <Component {...pageProps} />
          <MySnackbar />
        </Provider>
      </ThemeProvider>
    </CacheProvider>
  );
}


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  